import { graphql } from "gatsby";
import Link from "gatsby-link";
import { get, startCase, sortBy } from "lodash";
import React, { Fragment, useEffect, useState } from "react";
import { EVENTS, STATES } from "@upsolve/shared";
import { H5, HR, Small, theme } from "@upsolve/ui";
import styled from "styled-components";

import * as CONTENT_CREATION_METHODS from "../components/analytics/contentCreationMethods";
import * as PAGE_TYPES from "../components/Programmatic/pageTypes";
import Article from "../components/Layout/Article.div";
import AuthorBio from "../components/Layout/AuthorBio";
import AuthorsBlock from "../components/Layout/AuthorsBlock";
import BreadcrumbNav from "../components/Layout/BreadcrumbNav";
import CTASideBySideOfferings from "../components/CTA/CTASideBySideOfferings";
import CitationsList from "../components/Layout/CitationsList";
import ContentSectionFooter from "../components/Layout/ContentSectionFooter";
import MainFooter from "../components/Layout/Footer";
import MainHeader from "../components/Layout/Header";
import SEO from "../components/SEO/SEO";
import StateGuidesListPanel from "../components/Programmatic/StateGuidesListPanel";
import TableOfContents from "../components/Layout/TableOfContents";
import TopLevelLayout from "../components/Layout/TopLevelLayout";
import UpsolveTextBio from "../components/ProofBuilders/UpsolveTextBio";
import VideoInContext from "../components/Media/VideoInContext";
import checkForInlineCta from "../components/Contentful/checkForInlineCta";
import getCitationsFromRichText from "../components/Contentful/getCitationsFromRichText";
import getHeadersFromRichText from "../components/Contentful/getHeadersFromRichText";
import getWordCountFromRichText from "../components/Contentful/getWordCountFromRichText";
import pageTypeContentGroups from "../components/analytics/pageTypeContentGroups";
import richTextNodeToComponent from "../components/Contentful/richTextNodeToComponent";
import richTextNodesInSections from "../components/Contentful/richTextNodesInSections";
import { track } from "../components/analytics/track";
import withScrollWatcher from "../components/analytics/withScrollWatcher";
import { Ampify } from "../components/AMP/AmpContext";
import CTAUpsolveReviews from "../components/CTA/CTAUpsolveReviews";
import { learnTagsSortedByPriority } from "../components/Learn/learnTagPriority";
import { CTATarget } from "../components/CTA/getCTAData";
import { fixHeaderScrollOffset } from "../components/Layout/fixHeaderScrollOffset";
import { useChat } from "../components/TopOfFunnelAI/useChat";
import { TopOfFunnelAIChat } from "../components/TopOfFunnelAI/TopOfFunnelAIChat";
import trackComponentAction from "../components/analytics/trackComponentAction";
import { BankruptcyHeroCTA, NonbankruptcyHeroCTA } from "../components/CTA/HeroCTASets";
import { SoloModal } from "../components/CTA/SoloModal";
import ArticleSummaryMessageBox from "../components/TopOfFunnelAI/ArticleSummaryMessageBox";

const ArticleTitle = styled.h1`
  color: ${theme.colors.black[300]};
  font-size: 52px;
  font-weight: 500;
  line-height: 58px;
  letter-spacing: -0.5px;
  @media screen and (max-width: 45em) {
    font-size: 36px;
    line-height: 120%;
  }
`;

const templateProps = {
  pageComponentName: "PageTemplate",
  pageComponentVersion: "4",
};

const getArticleTarget = (tags: { value: string }[]) => {
  //future: use the graphQL query instead of this static list
  if (tags.find((tag) => tag.value === learnTagsSortedByPriority.STUDENT_LOANS)) {
    return CTATarget.STUDENT_LOANS;
  } else if (tags.find((tag) => tag.value === learnTagsSortedByPriority.CONSUMER_RIGHTS)) {
    return CTATarget.DEBT_TRIAGE;
  }
  return CTATarget.SCREENER;
};

/**
 * PageTemplate
 * - v0: Where we began.
 * - v1: Layout adjustments - condensed top, collapsed TOC, hide CTAs based on screen width
 * - v2: ContentSectionFooter removed, share button at top added, more intuitive Table of Contents
 * - v3: Updated CTA behavior to match LearnArticle - desktop/mobile separation, consistent placement
 * - v4: Added TopOfFunnelAIChat integration
 *
 * @component
 * @version 4
 */
const PageTemplate = (props: any) => {
  const { pageContext } = props;
  const { amp } = pageContext;
  const layout = props.data.page.layout || {};
  const wordCount = getWordCountFromRichText(get(props, "data.page.content.json", []));
  const articleTags = props.data.page.tags || [];

  const trackingPageContext = {
    contentCreationMethod: get(props, "data.page.contentTraits.creationMethod", CONTENT_CREATION_METHODS.WRITTEN),
    contentGroup:
      get(props, "data.page.contentTraits.contentGroup") || get(pageTypeContentGroups, get(props, "data.page.type")),
    contentLocalized:
      get(props, "data.page.contentTraits.contentLocalized") || get(props, "data.page.locality") != null,
    publishedAt: get(props, "data.page.publishedAt"),
    searchTermTargets: [get(props, "data.page.contentTraits.searchTermTarget")],
    tags: articleTags.map((tag: any) => ({ tag: tag.value })),
    updatedAt: get(props, "data.page.updatedAt"),
    wordCount,
    ...templateProps,
  };

  const children = (
    <TopLevelLayout
      showPersistentCta={false}
      persistentCtaText={layout.persistentCtaText}
      showBackground={layout.showBackground}
      backgroundTemplate={layout.backgroundTemplate}
      trackingPageContext={trackingPageContext}
    >
      <PageTemplateContents pageProps={props} trackingPageContext={trackingPageContext} />
      {/* useChat needs to be used inside TopLevelLayout because of the query client */}
    </TopLevelLayout>
  );

  return amp === true ? <Ampify>{children}</Ampify> : children;
};

type TPageTemplateContentsProps = {
  pageProps: any;
  trackingPageContext: any;
};

const PageTemplateContents = ({ pageProps, trackingPageContext }: TPageTemplateContentsProps) => {
  const { pageContext, scrollWatcher } = pageProps;
  const { content, createdAt, locality, seo, summary, title, type, updatedAt, video, reviewedAt } = pageProps.data.page;
  const { amp, districts } = pageContext;
  const layout = pageProps.data.page.layout || {};
  const wordCount = getWordCountFromRichText(get(pageProps, "data.page.content.json", []));

  // Get chat access from useChat hook
  const chat = useChat();

  // Function to open the AI chat - define once and pass to components
  const openAIChat = () => {
    if (chat && typeof chat.setIsOpen === "function") {
      chat.setIsOpen(true);
    }
  };

  const handleSendMessageFromArticleSummary = (message: string) => {
    openAIChat();
    chat.askQuestion(message, false);
    trackComponentAction({
      componentName: "articleSummaryMessageBox",
      actionId: "askAI",
      actionMethod: "click",
      pageContext: trackingPageContext,
    });
  };

  const onOpenAIWithMobileFooterCTA = () => {
    openAIChat();
    trackComponentAction({
      componentName: "mobileFooterCTA",
      actionId: "askAI",
      actionMethod: "click",
      pageContext: trackingPageContextTyped,
    });
  };

  const onOpenAIWithHeroCTA = () => {
    openAIChat();
    trackComponentAction({
      componentName: "heroCTA",
      actionId: "askAI",
      actionMethod: "click",
      pageContext: trackingPageContextTyped,
    });
  };

  // - Associated Page Data
  const clusterChildren = pageProps.data.childrenPages.edges
    .map((e: any) => e.node)
    .filter((n: any) => !n.path.includes("/questions/"));
  const clusterSiblings = pageProps.data.siblingPages.edges
    .map((e: any) => e.node)
    .filter((n: any) => !n.path.includes("/questions/"));
  // - Content
  const sectionHeaders = getHeadersFromRichText(content.json);
  const contentSections = richTextNodesInSections(content.json);
  const citations = getCitationsFromRichText(content.json);
  const hasInlineCta = checkForInlineCta(content.json);
  const authors = (get(pageProps, "data.page.authors") || [get(pageProps, "data.page.author")]).filter(
    (author: any) => author && author.name != null
  );
  const articleTags = pageProps.data.page.tags || [];

  const [isSoloModalOpen, setIsSoloModalOpen] = useState(false);
  const ctaTarget = getArticleTarget(articleTags);
  const useNonbankruptcyCTA = ctaTarget === CTATarget.DEBT_TRIAGE;

  const trackingPageContextTyped = {
    ...trackingPageContext,
    wordCount: wordCount || undefined,
  };

  const heroCTA = useNonbankruptcyCTA ? (
    <NonbankruptcyHeroCTA
      onClickAskUpsolveAIButton={onOpenAIWithHeroCTA}
      trackingPageContext={trackingPageContextTyped}
      onSoloModalOpen={() => setIsSoloModalOpen(true)}
    />
  ) : (
    <BankruptcyHeroCTA
      onClickAskUpsolveAIButton={onOpenAIWithHeroCTA}
      trackingPageContext={trackingPageContextTyped}
      ctaTarget={ctaTarget}
    />
  );

  const mobileCTA = useNonbankruptcyCTA ? (
    <NonbankruptcyHeroCTA
      onClickAskUpsolveAIButton={openAIChat}
      trackingPageContext={trackingPageContextTyped}
      onSoloModalOpen={() => setIsSoloModalOpen(true)}
    />
  ) : (
    <BankruptcyHeroCTA
      onClickAskUpsolveAIButton={openAIChat}
      trackingPageContext={trackingPageContextTyped}
      ctaTarget={ctaTarget}
    />
  );

  const renderSectionCTAs = (sectionIndex: number, sectionArray: any[], hasInlineCta: boolean) => {
    const shouldShowMobileCta = !hasInlineCta && sectionIndex === 0;
    const shouldShowReviewsCta = !hasInlineCta && Math.floor(sectionArray.length * 0.35) === sectionIndex;

    return (
      <>
        {shouldShowMobileCta && (
          <div className="mobile-cta-wrapper">
            <aside className="article__sidebar mobile-only">
              <div className="sidebar-hero__cta">{mobileCTA}</div>
            </aside>
          </div>
        )}
        {shouldShowReviewsCta && (
          <CTAUpsolveReviews
            key={"cta reviews"}
            dataTestId="inline-content-cta"
            trackingPageContext={trackingPageContext}
            ctaTarget={useNonbankruptcyCTA ? CTATarget.SCREENER : ctaTarget}
          />
        )}
      </>
    );
  };

  // On Mount
  useEffect(() => {
    track(EVENTS.PAGE_VIEW, { pageContext: trackingPageContextTyped });
  }, []);

  useEffect(() => {
    fixHeaderScrollOffset();
  }, []);

  // Mobile scroll control
  // this prevents the page from scrolling in the background when the chat or modal is open on mobile
  useEffect(() => {
    // same breakpoint as in TopOfFunnelAIChat.tsx
    if (window.innerWidth <= 720) {
      if ((chat && chat.isOpen) || isSoloModalOpen) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "";
      }

      return () => {
        document.body.style.overflow = "";
      };
    }
  }, [chat && chat.isOpen, isSoloModalOpen]);

  return (
    <>
      <SEO seoNode={seo} pageNode={pageProps.data.page} />
      <MainHeader
        allowMobileFooter={type !== PAGE_TYPES.JOB_LISTING}
        trackingPageContext={trackingPageContextTyped}
        ctaTarget={useNonbankruptcyCTA ? CTATarget.SCREENER : ctaTarget}
        onOpenTopOfFunnelAI={onOpenAIWithMobileFooterCTA}
        shouldShowNavPopUp={!(isSoloModalOpen || (chat && chat.isOpen))}
      />
      <Article className={type === PAGE_TYPES.JOB_LISTING ? "centered" : undefined}>
        <BreadcrumbNav />
        <div className="article__body">
          <div className="article__content">
            <div className="content-hero">
              <header className="content-hero__header" id="top">
                <ArticleTitle>{title}</ArticleTitle>
                {![PAGE_TYPES.BARE, PAGE_TYPES.JOB_LISTING].includes(type) && (
                  <Fragment>
                    <UpsolveTextBio
                      wordCount={wordCount || undefined}
                      trackingPageContext={trackingPageContextTyped}
                      ctaTarget={useNonbankruptcyCTA ? CTATarget.SCREENER : ctaTarget}
                    />
                    <HR />
                  </Fragment>
                )}
              </header>
            </div>
            <div className="content-body">
              <article className="content-body__container">
                {get(summary, "json") != null && (
                  <div className="content-body__lead">
                    <div className="summary">
                      <b>In a Nutshell</b>
                      {Array.prototype.map.call(summary.json.content, (c, i, a) =>
                        richTextNodeToComponent(c, i, a, { locality, trackingPageContext })
                      )}
                      <ArticleSummaryMessageBox handleSendMessage={handleSendMessageFromArticleSummary} />
                    </div>
                  </div>
                )}
                {type !== PAGE_TYPES.BARE && (
                  <AuthorsBlock authors={authors} createdAt={createdAt} updatedAt={updatedAt} reviewedAt={reviewedAt} />
                )}
                {type !== PAGE_TYPES.BARE && <HR />}
                {sectionHeaders.length > 0 && <TableOfContents sectionHeaders={sectionHeaders} />}
                {video != null && (
                  <section className="content-video">
                    <VideoInContext video={video} />
                  </section>
                )}

                <div className="rich-text-content">
                  {contentSections.length === 1
                    ? Array.prototype.map.call(contentSections[0], (c, i, a) => (
                        <Fragment key={i}>
                          {richTextNodeToComponent(c, i, a, { locality, districts, trackingPageContext })}
                        </Fragment>
                      ))
                    : Array.prototype.map.call(contentSections, (section, sectionIndex, sectionArray) => (
                        <Fragment key={sectionIndex}>
                          <section>
                            {Array.prototype.map.call(section, (c, i, a) => (
                              <Fragment key={i}>
                                {richTextNodeToComponent(c, i, a, { locality, districts, trackingPageContext })}
                              </Fragment>
                            ))}
                          </section>
                          {renderSectionCTAs(sectionIndex, sectionArray, hasInlineCta)}
                        </Fragment>
                      ))}
                </div>

                <HR />
                {citations.length > 0 && (
                  <Fragment>
                    <H5 as="h2">Sources:</H5>
                    <CitationsList citations={citations} />
                  </Fragment>
                )}
                <ContentSectionFooter />
                <HR />
                {authors.length > 0 && (
                  <div className="author-bios">
                    <div className="written-by">
                      <Small>Written By:</Small>
                    </div>
                    {authors.map((author: any) => (
                      <AuthorBio key={author.name} author={author} />
                    ))}
                    <div className="team">
                      <Link to="/team">Read About the Upsolve Team</Link>
                    </div>
                  </div>
                )}
              </article>
              {![PAGE_TYPES.BARE, PAGE_TYPES.JOB_LISTING].includes(type) && (
                <aside className="article__sidebar desktop-only">
                  {type !== PAGE_TYPES.JOB_LISTING && layout.showHeroCta !== false && heroCTA}
                </aside>
              )}
            </div>
          </div>
        </div>
        <div className="related-content">
          {type !== PAGE_TYPES.JOB_LISTING &&
            type !== PAGE_TYPES.BARE &&
            (clusterSiblings.length > 0 || clusterChildren.length > 0) && (
              <section>
                <H5>Related Reading</H5>
                <ul>
                  {Array.prototype.map.call(sortBy([...clusterChildren, ...clusterSiblings]), (c: any, cIndex) => (
                    <li key={cIndex}>
                      <a href={c.path}>
                        {get(c, "context.title") ||
                          `${STATES[c.path.slice(1, 2) as keyof typeof STATES] || ""} ${startCase(
                            c.path.slice(3).replace(/\//g, " ")
                          )}`.trim()}
                      </a>
                    </li>
                  ))}
                </ul>
              </section>
            )}
        </div>
      </Article>
      {![PAGE_TYPES.BARE, PAGE_TYPES.JOB_LISTING].includes(type) && (
        <CTASideBySideOfferings
          trackingPageContext={trackingPageContextTyped}
          ctaTarget={useNonbankruptcyCTA ? CTATarget.SCREENER : ctaTarget}
        />
      )}
      {[
        PAGE_TYPES.STATE_PAGE,
        PAGE_TYPES.STATE_BANKRUPTCY_FORMS,
        PAGE_TYPES.STATE_SUB_PAGE,
        PAGE_TYPES.STATE_MEANS_TEST,
      ].includes(type) && <StateGuidesListPanel />}
      <TopOfFunnelAIChat />

      {isSoloModalOpen && (
        <SoloModal onHide={() => setIsSoloModalOpen(false)} trackingPageContext={trackingPageContextTyped} />
      )}

      <MainFooter />
      {/* {type !== PAGE_TYPES.JOB_LISTING && layout.showDisabledReadingPrompt !== false && (
        <DisabledReadingPrompt
          isAllowedToRender={() => scrollWatcher.scrollPosition > scrollWatcher.documentHeight / 2}
          trackingPageContext={trackingPageContext}
          ctaTarget={useNonbankruptcyCTA ? CTATarget.SCREENER : ctaTarget}
        />
      )} */}
    </>
  );
};

export const pageQuery = graphql`
  query PageByPath(
    $canonicalPath: String!
    $childrenPathRegex: String
    $excludedChildrenPageTypes: [String]
    $excludedChildrenTemplates: String
    $siblingPathRegex: String
    $excludedSiblingPageTypes: [String]
    $excludedSiblingTemplates: String
  ) {
    page: contentfulPage(path: { eq: $canonicalPath }) {
      path
      title
      type
      content {
        json
      }
      summary {
        json
      }
      author {
        ...AuthorNode
      }
      createdAt
      updatedAt
      locality {
        level
        state
        city
      }
      tags {
        value
        label
      }
      contentTraits {
        contentCreationMethod
        contentGroup
        contentLocalized
        contentTopics
        searchTermTarget
      }
      layout {
        showPersistentCta
        showHeroCta
        showBackground
        showDisabledReadingPrompt
        persistentCtaText
        heroCtaType
        backgroundTemplate
      }
      video {
        ...VideoNode
      }
      seo {
        ...SeoNode
      }
    }

    childrenPages: allSitePage(
      filter: {
        path: { regex: $childrenPathRegex, ne: $canonicalPath }
        component: { regex: $excludedChildrenTemplates }
        context: { amp: { ne: true }, type: { nin: $excludedChildrenPageTypes } }
      }
    ) {
      edges {
        node {
          path
          component
          context {
            title
          }
        }
      }
    }

    siblingPages: allSitePage(
      filter: {
        path: { regex: $siblingPathRegex, ne: $canonicalPath }
        component: { regex: $excludedSiblingTemplates }
        context: { amp: { ne: true }, type: { nin: $excludedSiblingPageTypes } }
      }
    ) {
      edges {
        node {
          path
          component
          context {
            title
          }
        }
      }
    }
  }
`;

export default withScrollWatcher(PageTemplate);
